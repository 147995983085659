html {
  height: 100%;
  margin: 0;
  padding: 0;


  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

html, body {
  max-width: 100%;
  overflow-x: hidden;
}


.App {
  text-align: center;
}


.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.App-main {
  display: flex;
  background-color: #282c34;
  color: white;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.App-link {
  color: #61dafb;
}
